* {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

html,
body {
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  color: white;
  background-color: #172026;
  overflow-x: hidden;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button:focus {
  outline: 0;
}

.container {
  margin: auto;
}

.text-primary {
  color: #F0B90B;
}

.text-table-header {
  color: #AAB8C1;
}

.bg-dark {
  background: #070A0E;
}

.bg-primary {
  background: #F0B90B;
}

.card {
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(16, 21, 24, 0.05);
  border-radius: 4px;
  margin-bottom: 1.33rem;
}

/* ROADMAP */
.roadmap-wrapper {
  position: relative;
  margin-top: 50px;
}

.roadmap-wrapper .timeline {
  background: linear-gradient(90deg, #eac038 0%, #ffc300 100%);
  width: 3px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 14px;
}

.roadmap {
  list-style-type: none;
  margin: 0 0 0 -11px;
  padding: 0;
  position: relative;
  z-index: 1;
}

.roadmap li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2.5em;
  position: relative;
  flex: 1;
}

.roadmap li h1 {
  font-size: 20px;
  margin-bottom: 4px;
}

.roadmap li p {
  font-size: 14px;
  margin-bottom: 0;
}

.roadmap li .text {
  padding-left: 1.25em;
  width: 90%;
}

.roadmap li .marker {
  background-color: #5D5D7B;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 6px solid #434355;
}

.roadmap li:before {
  content: "";
  background-color: #888888;
  width: 3px;
  height: 200%;
  position: absolute;
  left: 11px;
  top: 30px;
  z-index: -1;
}

.roadmap li:last-child:before {
  height: 100%;
}

.roadmap .complete h1 {
  background: #eac038;
  background: linear-gradient(90deg, #eac038 0%, #ffc300 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.roadmap .complete p {
  color: white;
}

.roadmap .complete .marker {
  background-color: #ffffff;
  border-color: #eac038;
}

.roadmap .complete:before {
  width: 0;
}

.roadmap h1,
.roadmap p {
  color: #888888;
}


@media screen and (min-width: 442px) {
  .roadmap li:last-child {
    margin-bottom: 0;
  }

  .roadmap li:last-child:before {
    height: 50%;
  }

  .roadmap-wrapper .timeline {
    height: 95%;
  }
}

@media screen and (min-width: 992px) {
  .roadmap {
    max-width: 875px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1280px) {
  .roadmap {
    max-width: none;
    margin: auto;
    padding-bottom: 0;
  }

  .roadmap-wrapper {
    margin-top: 250px;
  }

  .roadmap-wrapper .timeline {
    width: 100%;
    height: 3px;
    top: 12px;
  }

  .roadmap {
    display: flex;
    justify-content: space-between;
  }

  .roadmap li {
    position: relative;
  }

  .roadmap li .text {
    padding-left: 0;
    width: 100%;
  }

  .roadmap li:before {
    height: 3px;
    width: 100%;
    top: 12px;
  }

  .roadmap li .marker {
    position: absolute;
  }

  .roadmap li:last-child:before {
    width: 100%;
    height: 3px;
  }

  .roadmap li:nth-child(odd) {
    margin-top: 50px;
  }

  .roadmap li:nth-child(odd) .marker {
    margin-top: -50px;
  }

  .roadmap li:nth-child(odd):before {
    top: -38px;
  }

  .roadmap li:nth-child(even) {
    margin-top: -150px;
  }

  .roadmap li:nth-child(even) .marker {
    margin-top: 150px;
  }

  .roadmap li:nth-child(even):before {
    top: 162px;
  }
}

.account-button::before {
  content: "BSC";
  width: 35px;
  height: 36px;
  font-size: 12px;
  line-height: 36px;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(240, 185, 11, 0.6);
  text-align: center;
  margin-right: -5px;
  font-weight: bold;
}

.investment-title {
  position: relative;
}

.investment-title::after {
  content: " ";
  position: absolute;
  bottom: -2px;
  left: 45%;
  width: 10%;
  height: 1px;
  background: #F0B90B;
}
